<template>
  <MainLayout fluid>
      <ManifestsTable />
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import ItemTableTemplate from '@/components/ItemTableTemplate'
import ItemTable from '@/components/ItemTable'
import Manifest from '@/resources/Manifest'

const ManifestsTable = {
  ...ItemTableTemplate,
  mixins: [ItemTable(Manifest)]
}

export default {
  components: { MainLayout, ManifestsTable },
  metaInfo() {
    return {
      title: this.$t('manifests.title'),
    }
  },
};
</script>